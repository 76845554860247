.F-Result {
    max-width: 600px;
    margin: 0 auto;
    padding: 25px;
    border: 1px solid var(--color-gray-20);
    border-radius: var(--radius-35);
}

.F-Result__number {
    padding: 100px 0;
    font-size: 26px;
    font-weight: 500;
    text-align: center;
}
