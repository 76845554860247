.UI-Textarea__label {
    display: block;
    min-height: 60px;
    margin-bottom: 20px;
}

.UI-Textarea__field-name {
    display: flex;
    align-items: center;
    height: 15px;
    margin-bottom: 8px;
    font-size: 14px;
    color: var(--color-gray);
}

.UI-Textarea__textarea {
    display: block;
    width: 100%;
    padding: 15px 13px;
    color: var(--color-white);
    font-size: 16px !important;
    border-radius: 5px;
    border: none;
    background-color: rgba(74, 74, 74, 0.3);
    outline: none;
}
