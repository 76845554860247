.F-Upload {
    position: relative;
    display: block;
    max-width: 600px;
    margin: 0 auto;
    padding: 25px;
    border: 1px solid var(--color-gray-20);
    border-radius: var(--radius-35);
}

.F-Upload__or {
    margin-bottom: 10px;
    font-size: 25px;
    font-weight: 500;
    text-align: center;
    color: var(--color-gray);
}

.F-Upload__hr {
    margin: 20px 0;
    border-top: 1px dashed var(--color-gray-20);
}

.F-Upload__footer {
    margin-top: 35px;
}

.F-Upload__header-text-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.F-Upload__past-example {
    color: var(--color-teal);
    cursor: pointer;
}

.F-Upload__wrap-spin {
    display: flex;
    justify-content: left;
}

.F-Upload__container-alert {
    margin-bottom: 25px;
}